import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { TopbarComponent } from './layout/topbar/topbar.component';
import { FooterbarComponent } from './layout/footerbar/footerbar.component';
import { CoreRoutingModule } from './core-routing.module';
import { MessageService } from 'primeng/api';
import { ButtonRendererComponent } from './shared/component/ButtonRendererComponent';
import { AgGridModule } from 'ag-grid-angular';
import { ImageRendererComponent } from './shared/component/ImageRendererComponent';

@NgModule({
  declarations: [SidebarComponent, TopbarComponent, FooterbarComponent,ButtonRendererComponent,ImageRendererComponent],
  imports: [
    CommonModule,
    CoreRoutingModule,
    AgGridModule
  ],
  exports:[SidebarComponent, TopbarComponent, FooterbarComponent,ButtonRendererComponent,ImageRendererComponent],
  entryComponents:[ButtonRendererComponent,ImageRendererComponent],
  providers:[MessageService]
})
export class CoreModule {

  constructor (@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }
 }
