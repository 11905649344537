import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  constructor(private router: Router, private sidebar: AppComponent) { }

  ngOnInit() {
  }

  logout() {
    sessionStorage.removeItem("CurrentUser");
    this.router.navigate(['/', 'authentication']);
    this.sidebar.showMenu = false;
  }

}
