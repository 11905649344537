import { Component, OnInit, AfterContentInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-button-renderer',
    template: `<img border="0" width="100" height="150" src=\"{{ params }}\">`
})

export class ImageRendererComponent implements ICellRendererAngularComp {
    baseURL = environment.API_URL;
    frm:string;
    params: any;
    path: string;

    agInit(params: any): void {
        debugger;
        this.params = params;
        this.frm=this.params.frm;
        if(this.frm == "WhoWeWorkFor")this.path = this.params.data.WhoWeWorkForGuid + "/" + this.params.data.Image;
        else this.path = this.params.data.InternalGalleryGuid + "/" + this.params.data.Image;
        //this.params = this.baseURL + "/UIDynamicImages/WhoWeWorkFor/" + this.path;
        this.params = this.baseURL + "/UIDynamicImages/" + this.frm +"/" + this.path;
    }

    refresh(params?: any): boolean {
        return true;
    }
}