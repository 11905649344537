import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'SamayaWebsite-Admin';
  headerFooter: boolean;
  showMenu: boolean = false;
  template: string =`<img class="custom-spinner-template" src="../assets/img/loader.gif">`
  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          //debugger;
          if (sessionStorage.getItem("CurrentUser") == null) {
            this.headerFooter = false;
            return;
          }

          if (sessionStorage.getItem("CurrentUser") != "[]") {
            this.headerFooter = true;
          }
          else {
            this.headerFooter = false;
          }
          /* this.headerFooter = (event.url !== '/login') */
        }
      });

    this.showMenu = false;
  }

}
