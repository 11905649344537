import { Component, OnInit, AfterContentInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'app-button-renderer',
    template: `<button class="{{btnClass}}" type="button" (click)="onClick($event)"><i class="{{fontAwesome}}"></i></button>`
    /* template: `<a href="JavaScript:Void(0);" style="cursor: pointer;" (click)="onClick($event)">{{label}}</a>` */
})

export class ButtonRendererComponent implements ICellRendererAngularComp {
    params: any;
    label: string;
    getLabelFunction: any;
    btnClass: string;
    fontAwesome: string;

    agInit(params: any): void {
        this.params = params;
        this.label = this.params.label || null;
        this.btnClass = this.params.btnClass || 'btn btn-default';
        this.fontAwesome = this.params.fontAwesome || 'fa fa-external-link';
    }

    refresh(params?: any): boolean {
        return true;
    }

    onClick($event) {
        if (this.params.onClick instanceof Function) {
            const params = {
                event: $event,
                rowData: this.params.node.data
            }
            this.params.onClick(params);
        }
    }
}