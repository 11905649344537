import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})
export class TopbarComponent implements OnInit {

  constructor(private router: Router, private sidebar: AppComponent) { }

  ngOnInit() {
  }

  logout()
  {
    sessionStorage.removeItem("CurrentUser");
    this.router.navigate(['/', 'authentication']);
  }

  toggleMenu() {
    this.sidebar.showMenu = !this.sidebar.showMenu;
  }

}
