import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(public http: HttpClient) { }

  login(username: string, password: string, grant_type: string) {
    debugger;
    var userData = "username=" + username + "&password=" + password + "&grant_type=" + grant_type + "";
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded','No-Auth':'True' });
    return this.http.post<any>(environment.API_URL + '/token',userData,{ headers: reqHeader })
        .pipe(map(user => {
            // login successful if there's a jwt token in the response
            if (user && user.access_token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                sessionStorage.setItem('CurrentUser', JSON.stringify(user));
            }
        }));
}

logout() {
    // remove user from local storage to log user out
    sessionStorage.removeItem('CurrentUser');
}

  get(resource: string): Observable<any[]> {
    return this.http.get<any[]>(environment.API_URL + '/api/' + resource);
  }

  Save(resource: string, obj: any): Observable<any> {
    
    let httpheaders = new HttpHeaders().set('Content-Type', 'application/json');

    let options = {
      headers: httpheaders
    }

    //if (obj.GUID == undefined || obj.GUID == '') {
      return this.http.post<any>(environment.API_URL + '/api/' + resource, obj, options);
    //}
    //else {
      //return this.http.put<any>(environment.API_URL + '/api/' + resource + '/' + obj.GUID, obj, options);
    //}
  }

  Delete(resource: string): Observable<number> {
    //return this.http.delete<number>(environment.API_URL + '/api/' + resource);
    return this.http.get<number>(environment.API_URL + '/api/' + resource);
  }

  SaveServices(resource: string, obj: any)
  {
    return this.http.post<any>(environment.API_URL + '/api/' + resource, obj);
  }
}
